export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آن لائن کھانے، گروسری، پارسل کی ترسیل اور فارمیسی کی خریداری کو تیز اور آسان بناتا ہے۔ گروسری ڈیلیور کریں اور بہترین دکانداروں سے اپنے پسندیدہ کھانے کا آرڈر دیں۔"])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہر چیز جو آپ کی ضرورت ہے، ابھی ڈیلیور کر دی گئی۔"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کھانا آن لائن آرڈر کریں، گروسری ڈیلیور کریں، اور اپنے علاقے کے بہترین گروسری اسٹور سے آن لائن خریداری کریں۔"])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقامی دکانداروں کو دریافت کریں جو آپ کی دہلیز پر پہنچائیں۔"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسان بزنس ٹرین میں شامل ہوں۔"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسان بزنس ٹرین میں شامل ہوں اور کامیابی کا آسان راستہ دریافت کریں۔ ابھی بورڈ میں شامل ہوں اور پریشانی سے پاک انٹرپرینیورشپ کا تجربہ کریں۔"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بیچنے والے بنیں۔"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بیچنے والے کے طور پر رجسٹر ہوں اور آج ہی اپنا کاروبار شروع کرنے کے لیے ایک دکان کھولیں۔"])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجسٹر کریں۔"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متعدد ادائیگی کے اختیارات"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آسان آرڈر مینجمنٹ"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متعدد ادائیگی کے اختیارات پیش کرتے ہیں۔"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروع سے آخر تک منظم کرنا۔"])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہمارے ساتھ سوار بنیں اور مزید کمائیں۔"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہماری ٹیم میں بطور سوار شامل ہوں اور اپنی کمائی کی صلاحیت کو غیر مقفل کریں۔ گاہکوں کو خوشی فراہم کرتے ہوئے لچکدار اوقات اور مسابقتی تنخواہ کی آزادی کا لطف اٹھائیں۔"])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوار بنیں۔"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہماری موبائل ایپ سے اپنی آن لائن دکان کو آسان بنائیں"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہماری موبائل ایپ کا استعمال کرتے ہوئے آسانی کے ساتھ اپنی آن لائن دکان کو ہموار کریں۔ چلتے پھرتے اپنے کاروبار کا نظم کریں اور کسی بھی وقت، کہیں بھی اپنے صارفین سے جڑے رہیں"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کمپنی"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہم سے رابطہ کریں۔"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رازداری کی پالیسی"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شرائط و ضوابط"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہمارے ساتھ کاروبار کریں۔"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سوار یا ڈرائیور کی ضرورت ہے؟"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پر فروخت کرنا چاہتے ہیں۔"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کھاتہ"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اکاؤنٹ کا انتظام"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احکامات"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایک اسٹور تلاش کریں۔"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقسام"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کاپی رائٹ"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جملہ حقوق محفوظ ہیں"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہم اپنی ویب سائٹ پر آپ کے تجربے کو بہتر بنانے کے لیے کوکیز کا استعمال کرتے ہیں۔ اس ویب سائٹ کو براؤز کرکے، آپ ہماری کوکیز کے استعمال سے اتفاق کرتے ہیں۔"])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول کریں۔"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاگ ان کریں"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بہترین فروخت ہونے والا"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تازہ ترین"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نمایاں"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلاش کریں۔"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصنوعات کی تلاش کریں۔"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلاش کریں۔"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلاش کے نتائج"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کے لئے تلاش کے نتائج"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کوئی نتیجہ نہیں"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کے لیے کوئی نتیجہ نہیں ملا"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمام دیکھیں"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دیکھیں مزید"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کم دکھائیں۔"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قریبی دکاندار"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کے لیے"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشہور وینڈرز"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اپنے علاقے میں بہترین دکاندار تلاش کریں۔"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہمارے یہاں آپ کے تمام پسندیدہ دکاندار ہیں۔"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نئے آنے والے"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نئی آمد"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلیش سیلز"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلیش سیل"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کتابیں"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کتاب"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آج کے انتخاب"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کوئی وینڈرز نہیں۔"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فائل منتخب کریں"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقبول خدمات"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سب سے اوپر ریٹیڈ"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسٹاک میں"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زخیرے سے باہر"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بہترین بیچنے والے"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گرم"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدار"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ٹوکری میں شامل کریں"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کیلئے تجویز کردہ"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاری رہے"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختیارات"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایک ہی وینڈر کی مصنوعات"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انہیں پروڈکٹ میں شامل کرنے کے لیے اختیارات منتخب کریں۔"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کی ٹوکری"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قیمت"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مصنوعات کی کل قیمت"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کل"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اس کو دیکھو"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کی ٹوکری خالی ہے"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کی ٹوکری خالی ہے. ابھی خریداری شروع کریں۔"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافی"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کوئی اضافی چیزیں دستیاب نہیں ہیں۔"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حکم کا خلاصہ"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اس ذیلی ٹوٹل میں ڈیلیوری یا پک اپ کی قیمت شامل نہیں ہے۔"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کل آئٹم"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذیلی ٹوٹل"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترسیل"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رعائیتی قیمت"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رعایت"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کل رقم"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کیا آپ کا اکاؤنٹ ہے؟"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈسکاؤنٹ کوڈ استعمال کریں۔"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈسکاؤنٹ حاصل کریں۔"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSL خفیہ کاری کے ساتھ خریداری"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شمولیت"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["یا"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہموار چیک آؤٹ کے لیے"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اس کو دیکھو"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ای میل"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاس ورڈ"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کے پاس ورڈ میں کم از کم 8 حروف، 1 بڑے، 1 چھوٹے حروف اور 1 نمبر کا ہونا ضروری ہے"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاسورڈ بھول گے؟"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ای میل یا پاس ورڈ غلط ہے۔"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اکاؤنٹ نہیں ہے؟"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پہلے سے ہی ایک اکاؤنٹ ہے؟"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اکاؤنٹ بنائیں"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado میں دوبارہ خوش آمدید!"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کھاتا کھولیں"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجسٹر کریں۔"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اکاؤنٹ بنا کر آپ ہماری پرائیویسی پالیسی اور شرائط و ضوابط سے اتفاق کرتے ہیں۔"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پورا نام"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فون نمبر"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پتہ"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شہر"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالت"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زپ"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملک"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاس ورڈ کی تصدیق کریں۔"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["یہاں مختلف کیٹیگریز سے اپنی پسندیدہ گروسری آرڈر کریں۔"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پک اپ آرڈر"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["براہ کرم اشارہ کریں کہ آیا آپ وینڈر سے آرڈر لیں گے۔"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["براہ کرم ترسیل کا پتہ منتخب کریں۔"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادائیگی کے طریقے"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آرڈر کی درخواست کریں۔"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترسیل کا پتہ"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایڈریس شامل کریں۔"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترمیم"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آرڈر تک تیار ہو جائے گا۔"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ٹیکسی آرڈر کریں۔"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["گاڑی کی قسم"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آج ہی پارسل لینے کا آرڈر دیں۔"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کس کو ادا کرنا ہے؟"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بھیجنے والا"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول کنندہ"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دوستوں اور اہل خانہ کو پارسل یا پیکج بھیجیں۔"])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج وصول کنندگان اور پیرامیٹرز کی معلومات"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج وصول کنندگان"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج کے پیرامیٹرز"])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اپنے پیکج کو ٹریک کریں۔"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اب حکم"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادا کریں۔"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالیہ احکامات"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکجز بھیجیں۔"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کی مقامی آن ڈیمانڈ کورئیر سروس"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمام کو صاف کریں"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج کی اقسام"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایک پیکیج کی قسم منتخب کریں۔"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کورئیر فروش"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایک کورئیر فروش منتخب کریں۔"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈیلیوری کی معلومات"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پک اپ اور منزل یہاں شامل کریں۔"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سے"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["کہاں..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پک اپ کی جگہ کا انتخاب کریں۔"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈراپ آف مقام کا انتخاب کریں۔"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسٹاپس شامل کریں۔"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج کی قسم منتخب کریں۔"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ایک کورئیر کا انتخاب کریں۔"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاریخ"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اگلے"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پچھلا"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول کنندگان"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول کنندہ کی معلومات"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابطے کی معلومات"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوٹس"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اس بات کی تصدیق کر کے میں قبول کرتا ہوں کہ اس آرڈر میں غیر قانونی/محدود اشیاء شامل نہیں ہیں، اگر غیر قانونی/محدود اشیاء ملیں"])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پارٹنر، وہ قانون نافذ کرنے والے حکام کو اس کی اطلاع دے سکتے ہیں۔ شرائط و ضوابط لاگو ہوتے ہیں۔"])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج کے پیرامیٹرز"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["یہاں تمام مطلوبہ پیکیج کے پیرامیٹرز کو پُر کریں۔"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وزن"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمبائی"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چوڑائی"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اونچائی"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈیلیوری کی تفصیلات"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اٹھاؤ"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈراپ آف"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادائیگی"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فاصلے"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈیلیوری چارجز"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پیکیج کی قسم کے چارجز"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول کنندہ"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول کنندہ کا نام درکار ہے۔"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول کنندہ کا فون نمبر درکار ہے۔"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ اپنا آرڈر کیسے وصول کرنا چاہیں گے؟"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کے حکم"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["چیک آؤٹ پر آگے بڑھیں۔"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہم درج ذیل ادائیگی کے اختیارات کو قبول کرتے ہیں۔"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترسیل کی فیس"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ٹیکس"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ڈرائیور ٹپ"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حکم صادر کریں"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["میرے احکام"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اکاؤنٹ کی ترتیبات"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ نے ابھی تک ہمارے ساتھ آرڈر نہیں کیا ہے۔ جب آپ کریں گے تو وہ یہاں دکھائی دیں گے۔"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پروڈکٹ"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذاتی معلومات"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ہیلو"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاس ورڈ کی تبدیلی"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["میرے پسندیدہ"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پرانا پاسورڈ"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نیا پاس ورڈ"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاس ورڈ کی تصدیق کریں۔"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پاس ورڈ اپ ڈیٹ کریں۔"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پتہ"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["پروفائل کو اپ ڈیٹ کریں۔"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آپ کا کوئی پتہ نہیں ہے۔"])}
  }
}