export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hace que las compras en línea de alimentos, abarrotes, entrega de paquetes y farmacia sean rápidas y fáciles. Obtenga comestibles entregados y ordene sus comidas favoritas de los mejores proveedores."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo lo que necesitas, entregado ahora"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordene comida en línea, obtenga comestibles entregados y compre en línea con la mejor tienda de comestibles en su área."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra proveedores locales que entregan en su puerta"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súmate al tren de los negocios fáciles"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únase al tren empresarial fácil y descubra un camino más sencillo hacia el éxito. Suba a bordo ahora y experimente el espíritu empresarial sin complicaciones"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conviértete en vendedor"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese como vendedor y abra una tienda para comenzar su negocio hoy."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Múltiples opciones de pago"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil gestión de pedidos"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofreciendo múltiples opciones de pago"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organización de principio a fin."])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conviértete en un ciclista con nosotros y gana más"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únase a nuestro equipo como ciclista y desbloquee su potencial de ingresos. Disfrute de la libertad de horarios flexibles y salarios competitivos mientras brinda felicidad a los clientes."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conviértete en un jinete"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz tu tienda online más fácil con nuestra aplicación móvil"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimice su tienda en línea con facilidad utilizando nuestra aplicación móvil. Administre su negocio sobre la marcha y manténgase conectado con sus clientes en cualquier momento y en cualquier lugar"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta con nosotras"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer negocios con nosotras"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Necesita pasajeros o conductores?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero vender en"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar cuenta"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra una tienda"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derechos de autor"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservados todos los derechos"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizamos cookies para mejorar su experiencia en nuestro sitio web. Al navegar por este sitio web, acepta nuestro uso de cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más vendido"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for products"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Results"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Results for"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found for"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestra menos"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedores cercanas"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usted"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores populares"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentre los mejores proveedores en su área"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenemos todos tus proveedores favoritos aquí."])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevos arrivales"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevos arrivales"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas rápidas"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venta rápida"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libros"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libro"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciones de hoy"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay vendedores"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija el archivo"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios Populares"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los más valorados"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agotado"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejor vendedora"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caliente"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir a la cesta"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado para ti"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos del mismo proveedor"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione opciones para agregarlas al producto."])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu carrito"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio total del producto"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu carrito esta vacío"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu carrito esta vacío. Comience a comprar ahora."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay extras disponibles"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del pedido"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este subtotal no incluye el costo de entrega o retiro"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículo total"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total parcial"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de descuento"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tener una cuenta?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa un código de descuento"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener descuento"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras con cifrado SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para un pago más fluido"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobación"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña debe incluir al menos 8 caracteres, 1 mayúscula, 1 letra minúscula y 1 número"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico o la contraseña son incorrectos"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tienes una cuenta?"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido de nuevo a Mercado!"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya tienes una cuenta?"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al crear una cuenta, acepta nuestra Política de privacidad y Términos y condiciones"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una cuenta"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí pide tu Supermercado favorito de diferentes categorías"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden para recoger"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique si recogerá el pedido del proveedorr"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione la dirección"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de pago"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar solicitud de pedido"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir dirección"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pedido estará listo para"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedir Taxi"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo de vehiculo"])},
  "parcel": {
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿A quién pagar?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remitente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptor"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido para recogida de paquete hoy"])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de parámetros y destinatarios del paquete"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatarias del paquete"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros del paquete"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíe paquetes o paquetes a amigos y familiares."])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de su paquete"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar ahora"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["órdenes recientes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar paquetes"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su servicio de mensajería local bajo demanda"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar todo"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de paquetes"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un tipo de paquete"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedores de mensajería"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un proveedor de mensajería"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de entrega"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregue Recogida y Destino aquí"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija una ubicación de recogida"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija una ubicación de entrega"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar paradas"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija un tipo de paquete"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige un mensajero"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatarias"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del destinatario"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de contacto"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notas"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al confirmar que acepto este pedido no contiene artículos ilegales/restringidos, si los artículos ilegales/restringidos son encontrados por "])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio, pueden denunciarlo a las autoridades policiales. Los términos y Condiciones aplican."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros del paquete"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, asegúrese de que el paquete está completo y que todos los artículos están en el paquete. Si el paquete no está completo, no podemos recibirlo."])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancho"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de entrega"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargos de entrega"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargos de tamaño del paquete"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient name is required"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient phone number is required"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo te gustaría recibir tu pedido?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pedido"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasar por la caja"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptamos las siguientes opciones de pago"])},
    "orderSummaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este subtotal incluye el costo de entrega o retiro"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de envío"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerencia para el conductor"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar pedido"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis ordenes"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones de la cuenta"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no ha realizado un pedido con nosotros. Cuando lo hagas aparecerán aquí."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacion personal"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de contraseña"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis favoritas"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña anterior"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmar Contraseña"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza contraseña"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del perfil"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes dirección"])}
  }
}